function Success() {
  return (
    <div>
      <h1 className="success">
        Thank you for submitting
        <br />
        your interest in Chatterbox!
      </h1>
      <h2>We will be in touch shortly.</h2>
    </div>
  );
}

export default Success;
