import React from "react";

function Footer() {
  return (
    <footer className="footer">
      If you need any help or advice, please email{" "}
      <a href="mailto: referrals@chatterbox.io">referrals@chatterbox.io</a>
    </footer>
  );
}

export default Footer;