import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Box, FormControlLabel, FormGroup, Grid } from "@mui/material";

import {
  StyledButton,
  StyledCheckbox,
  StyledFormControl,
  StyledMenuItem,
  StyledTextField,
} from "../style/styles";

import { base } from '../setup/airtable.setup';

function validateEmail(email) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i.test(email);
}

const licenceLengths = ["12 months", "6 months"];

const financeTypes = ["Self-financed", "Company reimbursement"];

function Form() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [refCode, setRefCode] = useState("");
  const [licenceLength, setLicenceLength] = useState("");
  const [financeType, setFinanceType] = useState("");
  const [checked, setChecked] = useState(false);
  const [fieldsFilled, setFieldsFilled] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (
      firstName &&
      lastName &&
      email &&
      refCode &&
      licenceLength &&
      financeType &&
      checked
    ) {
      setFieldsFilled(true);
    } else {
      setFieldsFilled(false);
    }
  }, [
    firstName,
    lastName,
    email,
    refCode,
    licenceLength,
    financeType,
    checked,
  ]);

  const handleChange = (setter, evt) => {
    setter(evt.target.value);
  };

  const handleClick = (evt) => {
    setChecked(evt.target.checked);
  };

  const handleEmail = (evt) => {
    const value = evt.target.value;
    const isValid = validateEmail(value);
    setValidEmail(isValid);
    setEmail(value);
  };

  const navigate = useNavigate();

  const handleSubmit = () => {
    base('Users').create(
      [
        {
          fields: {
            "First Name": firstName,
            "Last Name": lastName,
            Email: email,
            "Referral Code": refCode,
            "Licence Length": licenceLength,
            "Finance Type": financeType,
          },
        },
    ], function(err, records) {
      if (err) {
        setErrorMessage(err.message);
        console.error(err);
        return;
      }
      records.forEach(function (record) {
        console.log(record.getId());
      });
      setFirstName("");
      setLastName("");
      setEmail("");
      setRefCode("");
      setValidEmail(false);
      setLicenceLength("");
      setFinanceType("");
      setChecked(false);
      setFieldsFilled(false);
  
      navigate("/success");
    });
  };

  const handleKeyPress = (evt) => {
    if (evt.key === "Enter" && validEmail && fieldsFilled) {
      handleSubmit();
    }
  };

  return (
    <>
      <Box className="max-width">
        <h1>
          Thanks for your
          <br />
          interest in Chatterbox!
        </h1>
        <p className="subheading">
          Register your interest in learning with your coach below.
        </p>
      </Box>

      <Grid container className="max-width" spacing={1} columnSpacing={7}>
        <Grid item xxs={12}>
          <h2>1. Your personal information</h2>
        </Grid>

        <Grid item xxs={12} md={6}>
          <StyledFormControl fullWidth variant="outlined">
            <StyledTextField
              required
              id="outlined-required"
              label="First name"
              value={firstName}
              onKeyPress={handleKeyPress}
              onChange={(evt) => handleChange(setFirstName, evt)}
            />
          </StyledFormControl>
        </Grid>

        <Grid item xxs={12} md={6}>
          <StyledFormControl fullWidth variant="outlined">
            <StyledTextField
              required
              id="outlined-disabled"
              label="Last name"
              value={lastName}
              onKeyPress={handleKeyPress}
              onChange={(evt) => handleChange(setLastName, evt)}
            />
          </StyledFormControl>
        </Grid>

        <Grid item xxs={12}>
          <StyledFormControl fullWidth variant="outlined">
            <StyledTextField
              required
              id="outlined-required"
              label="Email"
              value={email}
              onKeyPress={handleKeyPress}
              onChange={handleEmail}
              error={!validEmail && !(email === "")}
              helperText={
                !validEmail && !(email === "") ? "Invalid email!" : ""
              }
            />
          </StyledFormControl>
        </Grid>

        <Grid item xxs={12}>
          <h2>2. Your course set up</h2>
        </Grid>
        <Grid item xxs={12} md={6}>
          <StyledFormControl fullWidth variant="outlined">
            <StyledTextField
              required
              id="refCode"
              label="Referral code"
              value={refCode}
              onKeyPress={handleKeyPress}
              onChange={(evt) => handleChange(setRefCode, evt)}
              helperText="Your Chatterbox coach will have been given a unique code. Please enter it here."
            />
          </StyledFormControl>
        </Grid>

        <Grid item xxs={12} md={6}>
          <StyledFormControl fullWidth variant="outlined">
            <StyledTextField
              required
              select
              id="refCode"
              label="Licence length"
              value={licenceLength}
              onKeyPress={handleKeyPress}
              onChange={(evt) => handleChange(setLicenceLength, evt)}
            >
              {licenceLengths.map((type) => (
                <StyledMenuItem value={type}>{type}</StyledMenuItem>
              ))}
            </StyledTextField>
          </StyledFormControl>
        </Grid>

        <Grid item xxs={12} md={6}>
          <StyledFormControl fullWidth variant="outlined">
            <StyledTextField
              required
              select
              id="financeType"
              label="Finance type"
              value={financeType}
              onKeyPress={handleKeyPress}
              onChange={(evt) => handleChange(setFinanceType, evt)}
            >
              {financeTypes.map((type) => (
                <StyledMenuItem value={type}>{type}</StyledMenuItem>
              ))}
            </StyledTextField>
          </StyledFormControl>
        </Grid>

        <Grid item xxs={12} sm={12}>
          <FormGroup>
            <FormControlLabel
              className="checkbox-label"
              control={
                <div className="checkbox-wrapper">
                  <StyledCheckbox
                    id="checked"
                    label="Checked"
                    value={checked}
                    onClick={handleClick}
                  />
                </div>
              }
              label="By clicking submit, I consent to the processing of my personal data in accordance with the Privacy Policy."
            />
          </FormGroup>
        </Grid>

        <Grid item xxs={12} md={6}>
          <StyledButton
            fullWidth
            disabled={!validEmail || !fieldsFilled}
            type="button"
            onClick={handleSubmit}
          >
            Submit
          </StyledButton>
          { errorMessage && <Alert severity="error"> { errorMessage } </Alert> }
        </Grid>
      </Grid>
    </>
  );
}

export default Form;
