import React from "react";
import { Grid } from "@mui/material";
import { Routes, Route } from "react-router-dom";

import Form from "./Form";
import Success from "./Success";
import picture from "../images/picture.png";

function Main() {
  return (
    <div className="main">
      <Grid container wrap="nowrap" spacing={0} columns={12}>
        <Grid item xxs={12} xs={7} sm={7} ml={7}>
          <div className="form-container">
            <Routes>
              <Route exact path="/" element={<Form />} />
              <Route path="/success" element={<Success />} />
            </Routes>
          </div>
        </Grid>
        <Grid
          item
          display={{ xxs: "none", xs: "block" }}
          xxs={0}
          xs={5}
          sm={5}
          ml={5}
        >
          <img className="side-picture" src={picture} alt="side" />
        </Grid>
      </Grid>
    </div>
  );
}

export default Main;