import React from "react";
import { useNavigate } from "react-router-dom";

import logo from "../images/chatterbox-logo.png";

function Header() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  }

  return (
    <header className="header">
      <img
        className="header__logo"
        src={logo}
        alt="logo"
        onClick={handleClick}
      />
    </header>
  );
}

export default Header;
