import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import styles from "./App.scss";

import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { MenuItem } from "@mui/material";

const StyledButton = styled(Button)`
  background: ${styles.mint};
  color: ${styles.darkGrey};

  height: 56px;
  margin-top: 40px;
  border-radius: 8px;

  font-family: Karla;
  font-weight: 700;
  font-size: ${styles.small};
  line-height: 16px;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  &:hover {
    background: ${styles.extraDarkMint};
    color: ${styles.darkGrey};
  }

  &:disabled {
    background: ${styles.extraSoftMint};
    color: ${styles.darkGreyDisabled};
  }
`;

const StyledTextField = styled(TextField)`
  & .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline} {
    border-color: ${styles.borderGrey};
    border-radius: 4px;
    style {
      top: -20px;
    }
  }
  & .${inputLabelClasses.outlined} {
    color: ${styles.darkGrey};
  }
  & .${outlinedInputClasses.input} {
    color: ${styles.darkGrey};
  }

  &:hover
    .${outlinedInputClasses.root}
    .${outlinedInputClasses.notchedOutline} {
    border-color: ${styles.darkGrey};
  }
  &:hover .${outlinedInputClasses.input} {
    color: ${styles.darkGrey};
  }
  &:hover .${inputLabelClasses.outlined} {
    color: ${styles.darkGrey};
  }

  &
    .${outlinedInputClasses.root}.${outlinedInputClasses.focused}
    .${outlinedInputClasses.notchedOutline} {
    border-color: ${styles.darkGrey};
  }
  &
    .${outlinedInputClasses.root}.${outlinedInputClasses.focused}
    .${outlinedInputClasses.input} {
    color: ${styles.darkGrey};
  }
  & .${inputLabelClasses.outlined}.${inputLabelClasses.focused} {
    color: ${styles.darkGrey};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &.Mui-selected {
    background-color: ${styles.extraSoftMint};
  }
  &:hover.Mui-selected {
    background-color: ${styles.extraSoftMint};
  }
  &:focus.Mui-selected {
    background-color: ${styles.extraSoftMint};
  }
`;

const StyledFormControl = styled(FormControl)`
  margin: 0px;
  margin-top: 22px;
`;

const StyledCheckbox = styled(Checkbox)`
  color: ${styles.mint};
  padding-top: 5px;
  &.Mui-checked {
    color: ${styles.mint};
  }
  transform: scale(1.2);
`;

export {
  StyledButton,
  StyledCheckbox,
  StyledTextField,
  StyledFormControl,
  StyledMenuItem,
};
